
































import {
  defineComponent,
  getCurrentInstance,
  ref,
  PropType,
  computed,
} from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'

import OutputConditionGroup from '@/components/organisms/h/outputCond/OutputConditionGroup.vue'
import { UiSAsyncExportStatus } from '@/types/generated/graphql'
import {
  UiMCategory0sDocument,
  UiMCategory1sDocument,
  UiMCategory2sDocument,
  UiMCategory3sDocument,
  StoresDocument,
  VendorsDocument,
  SaleItemsDocument,
  ExtractVUiPAreasDocument,
  Where,
  WhereOp,
  UiPCausalNamesDocument,
} from '@/types/generated/graphql'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_COND_ITEM,
  OUTPUT_COND_STORE,
  OUTPUT_EXTRACT_TARGET,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
  EXTRACT_INFO_CONST,
  OUTPUT_INFO_CONST,
  ITEM_STATUS,
  userExtractIsAdministratorOptions,
  ItemFeatureExtractAutoorderTypeOptions,
} from '@/utils/constant'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast, { genNotifyMessage } from '@/utils/toast'

export default defineComponent({
  data() {
    return {
      extractList: [],
      outputUseList: [],
      enableExtractTarget: false,
      disabled: true,
      extractTargetSelectVal: OUTPUT_EXTRACT_TARGET.specify,
    }
  },
  components: {
    OutputConditionGroup,
  },
  props: {
    item: {
      type: Object as PropType<UiSAsyncExportStatus>,
    },
  },
  watch: {
    item(param, param2) {
      this.exportStatus = param
      this.extractWhere(param)
    },
  },
  setup(props, context) {
    const instance = getCurrentInstance()
    if (!instance) {
      return
    }
    // called by parent component
    const show = (item: UiSAsyncExportStatus) => {
      // @ts-ignore
      instance.proxy.$refs.modal.show()
    }
    return { show }
  },
  methods: {
    createExtractList(type: string, where: string) {
      let extractList = []
      try {
        const exportCondition = JSON.parse(where)
        const extractInfoConst = EXTRACT_INFO_CONST[type]
        let category1Cd = []
        let category2Cd = []

        // 画面名
        extractList.push({ 0: '画面名', 1: this.$t('table.' + type) })
        // 抽出条件の一覧を追加する
        for (const i in extractInfoConst) {
          extractList.push({
            0: this.$t('extractCondition.' + i),
            1: '',
          })
          for (var h in exportCondition) {
            for (var columnIndex in extractInfoConst[i]['column']) {
              if (
                exportCondition[h]['field'][0] ==
                extractInfoConst[i]['column'][columnIndex]
              ) {
                // DBなどから名称を取得する項目
                if (extractInfoConst[i]['query']) {
                  const nowLength = extractList.length
                  switch (extractInfoConst[i]['query']) {
                    case 'UiMCategory0sDocument':
                      this.getCategory0Data(exportCondition[h]['value']).then(
                        (data: any) => {
                          let category0Name = []
                          for (var record in data) {
                            category0Name.push(
                              data[record]['node']['category0Cd'] +
                                ':' +
                                data[record]['node']['categoryName']
                            )
                          }
                          this.extractList[nowLength - 1]['1'] =
                            category0Name.join(', ')
                        }
                      )
                      break
                    case 'UiMCategory1sDocument':
                      category1Cd = exportCondition[h]['value']
                      this.getCategory1Data(exportCondition[h]['value']).then(
                        (data: any) => {
                          let category1Name = []
                          for (var record in data) {
                            category1Name.push(
                              data[record]['node']['category1Cd'] +
                                ':' +
                                data[record]['node']['categoryName']
                            )
                          }
                          this.extractList[nowLength - 1]['1'] =
                            category1Name.join(', ')
                        }
                      )
                      break
                    case 'UiMCategory2sDocument':
                      category2Cd = exportCondition[h]['value']
                      this.getCategory2Data(
                        category1Cd,
                        exportCondition[h]['value']
                      ).then((data: any) => {
                        let category2Name = []
                        for (var record in data) {
                          category2Name.push(
                            data[record]['node']['category2Cd'] +
                              ':' +
                              data[record]['node']['categoryName']
                          )
                        }
                        this.extractList[nowLength - 1]['1'] =
                          category2Name.join(', ')
                      })
                      break
                    case 'UiMCategory3sDocument':
                      this.getCategory3Data(
                        category1Cd,
                        category2Cd,
                        exportCondition[h]['value']
                      ).then((data: any) => {
                        let category3Name = []
                        for (var record in data) {
                          category3Name.push(
                            data[record]['node']['category3Cd'] +
                              ':' +
                              data[record]['node']['categoryName']
                          )
                        }
                        this.extractList[nowLength - 1]['1'] =
                          category3Name.join(', ')
                      })
                      break
                    case 'StoresDocument':
                      this.getStoresData(exportCondition[h]['value']).then(
                        (data: any) => {
                          let storeName = []
                          for (var record in data) {
                            storeName.push(
                              data[record]['node']['storeCd'] +
                                ':' +
                                data[record]['node']['storeName']
                            )
                          }
                          this.extractList[nowLength - 1]['1'] =
                            storeName.join(', ')
                        }
                      )
                      break
                    case 'SaleItemsDocument':
                      this.getSaleItemsData(exportCondition[h]['value']).then(
                        (data: any) => {
                          let saleItemName = []
                          for (var record in data) {
                            saleItemName.push(
                              data[record]['node']['saleItemCd'] +
                                ':' +
                                data[record]['node']['saleItemName']
                            )
                          }
                          this.extractList[nowLength - 1]['1'] =
                            saleItemName.join(', \n')
                        }
                      )
                      break
                    case 'ExtractVUiPAreasDocument':
                      this.getVUiPAreasData(exportCondition[h]['value']).then(
                        (data: any) => {
                          let areaName = []
                          for (var record in data) {
                            areaName.push(
                              data[record]['node']['areaCd'] +
                                ':' +
                                data[record]['node']['areaName']
                            )
                          }
                          this.extractList[nowLength - 1]['1'] =
                            areaName.join(', ')
                        }
                      )
                      break
                    case 'VendorsDocument':
                      console.log('vendor')
                      this.getVendorsData(exportCondition[h]['value']).then(
                        (data: any) => {
                          let vendorsName = []
                          for (var record in data) {
                            vendorsName.push(
                              data[record]['node']['vendorCd'] +
                                ':' +
                                data[record]['node']['vendorName']
                            )
                          }
                          this.extractList[nowLength - 1]['1'] =
                            vendorsName.join(', ')
                        }
                      )
                      break
                    case 'UiPCausalNamesDocument':
                      this.getCausalNamesData(exportCondition[h]['value']).then(
                        (data: any) => {
                          let causalName = []
                          for (var record in data) {
                            causalName.push(
                              data[record]['node']['causalCd'] +
                                ':' +
                                data[record]['node']['causalName']
                            )
                          }
                          this.extractList[nowLength - 1]['1'] =
                            causalName.join(', ')
                        }
                      )
                      break
                    case 'ItemStatus':
                      this.getItemStatus(
                        exportCondition[h]['value'].sort()
                      ).then((data: any) => {
                        const itemStatus = data
                        this.extractList[nowLength - 1]['1'] =
                          itemStatus.join(', ')
                      })
                      break
                    case 'isAdministrator':
                      this.getIsAdministratorOptions(
                        exportCondition[h]['value'].sort()
                      ).then((data: any) => {
                        const isAdministrator = data
                        this.extractList[nowLength - 1]['1'] =
                          isAdministrator.join(', ')
                      })
                      break
                    case 'AutoorderType':
                      this.getAutoorderTypeOptions(
                        exportCondition[h]['value'].sort()
                      ).then((data: any) => {
                        const autoorderType = data
                        this.extractList[nowLength - 1]['1'] =
                          autoorderType.join(', ')
                      })
                      break
                    default:
                      break
                  }
                } else {
                  // ExpireDateの場合,日付のToがDefaultとなっているため連結
                  if (i == 'expireDate') {
                    extractList.slice(-1)[0]['1'] =
                      exportCondition[h]['value'] +
                      ' 〜 ' +
                      extractInfoConst[i]['default']
                    continue
                  }
                  // 連結する日付のカラム
                  if (
                    extractInfoConst[i]['column'].length > 1 &&
                    extractList.slice(-1)[0]['1'].length
                  ) {
                    extractList.slice(-1)[0]['1'] =
                      exportCondition[h]['value'] +
                      ' 〜 ' +
                      extractList.slice(-1)[0]['1']
                    continue
                  }
                  // 名称を取得しない項目
                  extractList.slice(-1)[0]['1'] =
                    exportCondition[h]['value'].join(', ')
                }
              }
            }
            // デフォルト値があるカラムの設定
            if (
              extractList.slice(-1)[0]['1'] == '' &&
              extractInfoConst[i]['default']
            ) {
              extractList.slice(-1)[0]['1'] = extractInfoConst[i]['default']
            }
          }
        }
        this.extractList = extractList
        return
      } catch (e) {
        return where
      }
    },
    createOutputList(type: string, where: string) {
      let outputUseList = []
      try {
        this.enableExtractTarget = false
        const outputCondition = JSON.parse(where)
        const outputInfoConst = OUTPUT_INFO_CONST[type]
        for (const i in outputInfoConst) {
          if (outputInfoConst[i]['outputCondType'] == 'ExtractTarget') {
            this.enableExtractTarget = true
          }
          outputUseList.push({
            outputCondType: outputInfoConst[i]['outputCondType'],
            displayItems: outputInfoConst[i]['displayItems'],
            defaultSelected: [],
            disabledItems: outputInfoConst[i]['displayItems'],
            isTanaPattern: outputInfoConst[i]['isTanaPattern'] || false,
          })
          for (var h in outputCondition) {
            if (outputCondition[h]['field'][0] == outputInfoConst[i]['field']) {
              outputUseList.slice(-1)[0]['defaultSelected'] =
                outputCondition[h]['value']
            }
          } // デフォルト値があるカラムの設定
          if (
            !outputUseList.slice(-1)[0]['defaultSelected'].length &&
            outputInfoConst[i]['default'].length
          ) {
            if (outputInfoConst[i]['outputCondType'] == 'ExtractTarget') {
              this.extractTargetSelectVal = outputInfoConst[i]['default']
              continue
            }
            outputUseList.slice(-1)[0]['defaultSelected'] =
              outputInfoConst[i]['default']
          }
        }
        this.outputUseList = outputUseList
        return
      } catch (e) {
        return where
      }
    },
    extractWhere(item: UiSAsyncExportStatus) {
      const lines = item.graphqlQuery.split(`\n`)
      const type = lines.at(0)?.replace(/^\s*#\s*/, '')
      const where = lines.at(1)?.replace(/^\s*#\s*/, '') || ''
      this.createExtractList(type, where)
      this.createOutputList(type, where)
    },
    async getCategory0Data(category0Cd: string[]) {
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['category0Cd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      setWhereValue(where, 'category0Cd', category0Cd)
      // console.log({ where })
      try {
        const result = await this.$apollo.query({
          query: UiMCategory0sDocument,
          variables: { where: where },
        })
        const data = result.data.uiMCategory0s.edges
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getCategory1Data(category1Cd: string[]) {
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['category1Cd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      setWhereValue(where, 'category1Cd', category1Cd)
      // console.log({ where })
      try {
        const result = await this.$apollo.query({
          query: UiMCategory1sDocument,
          variables: { where: where },
        })
        const data = result.data.uiMCategory1s.edges
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getCategory2Data(category1Cd: string[], category2Cd: string[]) {
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['category1Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category2Cd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      setWhereValue(where, 'category1Cd', category1Cd)
      setWhereValue(where, 'category2Cd', category2Cd)
      // console.log({ where })
      try {
        const result = await this.$apollo.query({
          query: UiMCategory2sDocument,
          variables: { where: where },
        })
        const data = result.data.uiMCategory2s.edges
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getCategory3Data(
      category1Cd: string[],
      category2Cd: string[],
      category3Cd: string[]
    ) {
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['category1Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category2Cd'],
          op: WhereOp.In,
          value: [''],
        },
        {
          field: ['category3Cd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      setWhereValue(where, 'category1Cd', category1Cd)
      setWhereValue(where, 'category2Cd', category2Cd)
      setWhereValue(where, 'category3Cd', category3Cd)
      // console.log({ where })
      try {
        const result = await this.$apollo.query({
          query: UiMCategory3sDocument,
          variables: { where: where },
        })
        const data = result.data.uiMCategory3s.edges
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getStoresData(storeCd: string[]) {
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['storeCd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      setWhereValue(where, 'storeCd', storeCd)
      // console.log({ where })
      try {
        const result = await this.$apollo.query({
          query: StoresDocument,
          variables: { where: where },
        })
        const data = result.data.stores.edges
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getSaleItemsData(saleItemCd: string[]) {
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['saleItemCd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      setWhereValue(where, 'saleItemCd', saleItemCd)
      // console.log({ where })
      try {
        const result = await this.$apollo.query({
          query: SaleItemsDocument,
          variables: { where: where },
        })
        const data = result.data.saleItems.edges
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getVUiPAreasData(areaCd: string[]) {
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['areaCd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      setWhereValue(where, 'areaCd', areaCd)
      // console.log({ where })
      try {
        const result = await this.$apollo.query({
          query: ExtractVUiPAreasDocument,
          variables: { where: where },
        })
        const data = result.data.vUiPAreas.edges
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getVendorsData(vendorCd: string[]) {
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['vendorCd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      setWhereValue(where, 'vendorCd', vendorCd)
      // console.log({ where })
      try {
        const result = await this.$apollo.query({
          query: VendorsDocument,
          variables: { where: where },
        })
        // console.log(result)
        const data = result.data.vendors.edges
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getCausalNamesData(vendorCd: string[]) {
      const SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['causalCd'],
          op: WhereOp.In,
          value: [''],
        },
      ]
      const where = JSON.parse(JSON.stringify(SEARCH_CONDITION))
      setWhereValue(where, 'causalCd', vendorCd)
      // console.log({ where })
      try {
        const result = await this.$apollo.query({
          query: UiPCausalNamesDocument,
          variables: { where: where },
        })
        // console.log(result)
        const data = result.data.uiPCausalNames.edges
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getItemStatus(itemStatus: string[]) {
      try {
        let data = []
        for (const val of itemStatus) {
          for (const status in ITEM_STATUS) {
            if (ITEM_STATUS[status] == val) {
              data.push(val + ':' + this.$t('label.' + status))
            }
          }
        }
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getIsAdministratorOptions(options: string[]) {
      try {
        let data = []
        for (const val of options) {
          for (const index in userExtractIsAdministratorOptions) {
            if (userExtractIsAdministratorOptions[index].value == val) {
              data.push(
                val + ':' + userExtractIsAdministratorOptions[index].text
              )
            }
          }
        }
        return data
      } catch (e) {
        console.error(e)
      }
    },
    async getAutoorderTypeOptions(options: string[]) {
      try {
        let data = []
        for (const val of options) {
          for (const index in ItemFeatureExtractAutoorderTypeOptions) {
            if (ItemFeatureExtractAutoorderTypeOptions[index].value == val) {
              data.push(
                val + ':' + ItemFeatureExtractAutoorderTypeOptions[index].text
              )
            }
          }
        }
        return data
      } catch (e) {
        console.error(e)
      }
    },
  },
})
